import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  public subjectError: Subject<string> = new Subject();
  public addError(error: string, errorResponse: unknown): void {
    if (errorResponse instanceof HttpErrorResponse && errorResponse.status !== 401) {
      this.subjectError.next(error);
    }
  }

}

export const isCommandValidationError = (response: HttpErrorResponse): boolean => response.status === 400 && typeof response.error === 'string' && response.error.startsWith('Validation');
export const isOtherValidationError = (response: HttpErrorResponse): boolean => response.status === 400 && typeof response.error === 'object' && response.error.title === 'One or more validation errors occurred.';
export const isStatementOfNoLossDocumentationRequiredError = (response: HttpErrorResponse): boolean => {
  return isOtherValidationError(response) && !!response.error.errors['statementOfNoLossDocumentationRequired'];
}
export const isExperienceModValidationExceptionError = (response: HttpErrorResponse): boolean => {
  return isOtherValidationError(response) && !!response.error.errors['experienceModValidationException'];
}
export const isDuplicateFEINException = (response: HttpErrorResponse): boolean => {
  return isOtherValidationError(response) && !!response.error.errors['duplicates'];
}
