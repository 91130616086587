import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserManager, User, UserManagerSettings } from 'oidc-client-ts';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthContext } from '../models/auth-context';
import { environment } from 'src/environments/environment';
import { SimpleClaim } from '../models/simple-claim';
import { Permission, PermissionAction, PermissionEntity } from '../Entities/Auth';
import { UserProfile } from '../models/user-profile';
import { CarrierFeature } from '@shared/Entities/Companies/Enums/carrier-feature';

@Injectable({
  providedIn: 'root'
})
export class AuthHelper {
  authContext: Observable<AuthContext>; // current auth context observable
  loginChanged: Observable<boolean>; // is logged in observable

  private _userManager: UserManager; // oidc user manager
  private _user: User; // oidc client user record
  private _authContextSubject = new BehaviorSubject<AuthContext>(null); // auth context behavior subject
  private _loginChangedSubject = new BehaviorSubject<boolean>(false); // logged in subject

  constructor(private _httpClient: HttpClient) {
    this.loginChanged = this._loginChangedSubject.asObservable();
    this.authContext = this._authContextSubject.asObservable();

    const stsSettings: UserManagerSettings = {
      authority: environment.stsAuthority,
      client_id: environment.clientId,
      redirect_uri: `${environment.clientRoot}signin-callback`,
      scope: [
        'openid',
        'profile',
        'vtg-engine-rating.external',
        'vtg-service-auth.external',
        'vtg-service-company.external',
        'vtg-service-resource.external',
        'vtg-service-submission.external',
        'vtg-service-task.external',
        'vtg-service-vector.external',
        'vtg-service-notification.external',
        'vtg-service-note.external'
      ].join(' '),
      response_type: 'code',
      post_logout_redirect_uri: `${environment.clientRoot}signout-callback`,
      filterProtocolClaims: false,
      automaticSilentRenew: true,
      silent_redirect_uri: `${environment.clientRoot}assets/silent-callback.html`
    };
    this._userManager = new UserManager(stsSettings);
    this._userManager.events.addAccessTokenExpired(_ => {
      this._authContextSubject.next(null);
      this._loginChangedSubject.next(false);
    });
    this._userManager.events.addUserLoaded(user => {
      if (this._user !== user) {
        this._user = user;
        this.loadSecurityContext();
        this._loginChangedSubject.next(!!user && !user.expired);
      }
    });

  }

  public getAuthContext(): AuthContext {
    return this._authContextSubject.value;
  }

  public login(url?: string) {
    return this._userManager.signinRedirect({ state: { url } });
  }

  public getUserProfile(): Promise<User> {
    return this._userManager.getUser();
  }

  public isLoggedIn(): Promise<boolean> {
    return this._userManager.getUser().then(user => {
      const userCurrent = !!user && !user.expired;
      if (this._user !== user) {
        this._loginChangedSubject.next(userCurrent);
      }
      if (userCurrent && !this._authContextSubject.value) {
        this.loadSecurityContext();
      }
      this._user = user;
      return userCurrent;
    });
  }

  public completeLogin() {
    return this._userManager.signinRedirectCallback().then(user => {
      this._user = user;
      this._loginChangedSubject.next(!!user && !user.expired);
      return user;
    });
  }

  public logout() {
    return this._userManager.signoutRedirect();
  }

  public completeLogout() {
    this._user = null;
    this._authContextSubject.next(null);
    this._loginChangedSubject.next(false);
    return this._userManager.signoutRedirectCallback();
  }

  public getAccessToken() {
    return this._userManager.getUser().then(user => {
      if (!!user && !user.expired) {
        return user.access_token;
      } else {
        return null;
      }
    });
  }

  public hasAccessToAgencyPortal(): boolean {
    return this._authContextSubject.value?.isAgency;
  }

  public hasAccessToCarrierPortal(): boolean {
    return this._authContextSubject.value?.isCarrier;
  }

  public hasAccessToAdminPortal(): boolean {
    return this._authContextSubject.value?.isAdmin;
  }

  public hasReadAccess(permissionEntity: PermissionEntity): boolean {
    return this.hasPermissionAccess(permissionEntity, 'Read');
  }

  public hasEditAccess(permissionEntity: PermissionEntity): boolean {
    return this.hasPermissionAccess(permissionEntity, 'Edit');
  }

  public hasCreateAccess(permissionEntity: PermissionEntity): boolean {
    return this.hasPermissionAccess(permissionEntity, 'Create');
  }

  public hasDeleteAccess(permissionEntity: PermissionEntity): boolean {
    return this.hasPermissionAccess(permissionEntity, 'Delete');
  }

  public checkPermissionAccess(access: { entity: PermissionEntity; action: PermissionAction }[]): boolean {
    if (!access || access.length === 0) {
      return true;
    }
    const outcome = access?.some(a => {
      switch (a.action) {
        case 'Read':
          return this.hasReadAccess(a.entity);
        case 'Create':
          return this.hasCreateAccess(a.entity);
        case 'Edit':
          return this.hasEditAccess(a.entity);
        case 'Delete':
          return this.hasDeleteAccess(a.entity);
        default:
          return false;
      }
    }) ?? false;
    return outcome;
  }

  public checkForPermissions(userPermissions: Permission[]): boolean {
    const result = userPermissions.some(p => p.Accesses.some(a => a.Actions.length > 0));
    return result;
  }

  private loadSecurityContext() {
    this._httpClient
      .get<any>(`${environment.baseAuthUrl}AuthContext`)
      .subscribe({
        next: context => {
          const authContext = new AuthContext();
          authContext.claims = context.Claims as SimpleClaim[];
          authContext.features = context.Features as string[];
          authContext.userProfile = context.UserProfile as UserProfile;
          this._authContextSubject.next(authContext);
        }
      });
  }

  private hasPermissionAccess(permissionEntity: PermissionEntity, permissionAction: PermissionAction): boolean {
    const user = this._authContextSubject.value?.userProfile;
    if (!user?.UserPermissions) {
      return false;
    }
    const result = user.UserPermissions.some(p => p.Accesses.some(a => a.Entity === permissionEntity && a.Actions.some(ac => ac === permissionAction)));
    return result;
  }

  public hasFeatureAccess(feature: CarrierFeature | null | undefined): boolean {
    if (!feature) {
      return true;
    }
    const user = this._authContextSubject.value;
    if (!user?.features) {
      return false;
    }
    return user.features.some(f => f === feature);
  }
}
