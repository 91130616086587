import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelper } from '@shared/helpers/authHelper.provider';
import { map, skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit {
  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  loading = false;
  error = false;
  username = '';
  password = '';

  constructor(
    private router: Router,
    private authHelper: AuthHelper
  ) { }

  async ngAfterViewInit() {
    const isLoggedIn = await this.authHelper.isLoggedIn();

    if (!isLoggedIn) {
      this.login();
      return;
    }

    const user = await this.authHelper.getUserProfile();

    const videoDisabled = await this.authHelper.authContext
      .pipe(
        skipWhile(v => !v),
        take(1),
        map(context => context.userProfile.DisableStartupVideo)
      ).toPromise();

    if (videoDisabled) {
      this.redirect(user);
      return;
    }

    const video = this.video.nativeElement;

    video.addEventListener('ended', (e) => {
      this.redirect(user);
    });

    try {
      video.muted = false;
      await video.play();
    } catch {
      this.redirect(user);
    }

  }

  private login() {
    this.authHelper.login();
  }

  private redirect(user) {
    const role = user.profile['role'];
    if (role === 'Carrier') {
      this.router.navigateByUrl('carrier');
    } else if (role === 'Agency') {
      this.router.navigateByUrl('agency');
    } else if (role === 'Admin') {
      this.router.navigateByUrl('admin');
    } else {
      this.router.navigateByUrl('unauthorized');
    }
  }
}
