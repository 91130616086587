<div class="flex justify-center items-center">
  <video
    #video
    preload="auto"
  >
    <source
      src="assets/images/light-sphere.mp4"
      type="video/mp4"
    >
    <mat-spinner></mat-spinner>
  </video>
</div>