import { UserProfile } from './user-profile';
import { SimpleClaim } from './simple-claim';

export class AuthContext {
  claims: SimpleClaim[];
  features: string[];
  userProfile: UserProfile;

  get Id() {
    return this.userProfile?.Id;
  }

  get Email() {
    return this.userProfile?.Email;
  }

  get UserName() {
    return `${this.userProfile?.FirstName} ${this.userProfile?.LastName}`;
  }

  get CompanyId() {
    return this.userProfile?.CompanyId;
  }

  get CompanyName() {
    return this.userProfile?.CompanyName;
  }

  /**
   * Returns the value for the first claim of the specified type, otherwise null if the claim is not present.
   *
   * @param claimType specified claim type
   * @returns The value of the first instance of the specified claim type, or null if the claim is not present.
   */
  findFirstValue(claimType: string): string | null {
    return this.claims?.find(c => c.Type === claimType)?.Value;
  }

  hasFeature(feature: string) {
    return !!this.features && !!this.features.find(f => f === feature);
  }

  get isAdmin() {
    return !!this.claims && !!this.claims.find(c =>
      c.Type === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' && c.Value === 'Admin');
  }

  get isAgency() {
    return !!this.claims && !!this.claims.find(c =>
      c.Type === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' && c.Value === 'Agency');
  }

  get isCarrier() {
    return !!this.claims && !!this.claims.find(c =>
      c.Type === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' && c.Value === 'Carrier');
  }

  get hasAcceptedTerms() {
    return this.userProfile.HasAcceptedTerms;
  }

  get lastAcceptedTermsVersion() {
    return this.userProfile.LastAcceptedTermsVersion;
  }
}
